import clsx from 'clsx'
import { MouseEvent, ReactNode, useCallback } from 'react'

type Props = {
  href: string
  className?: string
  children: ReactNode
}

let ExtLink = ({ href, className, children }: Props) => {
  let handleClick = useCallback(
    (
      e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
      href: string
    ) => {
      e.preventDefault()
      window.open(href, '_blank')
    },
    []
  )

  return (
    <a
      href={href}
      className={clsx(className)}
      onClick={(e) => handleClick(e, href)}
    >
      {children}
    </a>
  )
}

export default ExtLink
