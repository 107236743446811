import { AppProps } from '@types'
import { RootErrorFallback } from 'components/common/ErrorComponent'
import { BASE_URL } from 'config/constants'
import 'keen-slider/keen-slider.min.css'
import { inProduction } from 'lib/misc'
import { zodCustomErrorMap } from 'lib/zodCustomErrorMap'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import 'styles/index.css'
import { setErrorMap } from 'zod'
import { store } from '../store'

const AppWatcher = dynamic(() => import('components/AppWatcher'))

const MyApp = ({ Component, pageProps }: AppProps) => {
  let getLayout = Component.getLayout ?? ((page) => page)

  const { asPath } = useRouter()

  useEffect(() => {
    setErrorMap(zodCustomErrorMap('ru'))
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && inProduction) {
      window.ym!(16650304, 'hit', asPath)
    }
  }, [asPath])

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1 maximum-scale=1'
        />
        <link rel='preconnect' href={BASE_URL} />
        <link rel='preconnect' href='https://mc.yandex.ru' />
      </Head>

      <Script id='ya' strategy='afterInteractive'>
        {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(16650304, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
        ecommerce:"dataLayer"
   });`}
      </Script>

      <noscript>
        <div>
          <img
            src='https://mc.yandex.ru/watch/51522458'
            style={{ position: 'absolute', left: '-9999px' }}
            alt=''
          />
        </div>
      </noscript>

      <Provider store={store}>
        <ErrorBoundary FallbackComponent={RootErrorFallback}>
          <AppWatcher />
          {getLayout(<Component {...pageProps} />)}
        </ErrorBoundary>
      </Provider>
    </>
  )
}

export default MyApp
