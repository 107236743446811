import clsx from 'clsx'
import IntLink from 'components/common/IntLink'
import LogInIcon from 'components/icons/LogInIcon'
import HeaderLogo from 'components/logos/HeaderLogo'
import MobileLogo from 'components/logos/MobileLogo'
import { CONTACT_PHONE } from 'config/constants'
import useMenus from 'hooks/useMenus'
import { isAbsent, isNonEmptyArray } from 'lib/utils'
import dynamic from 'next/dynamic'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { IMenuItem, selectCities } from 'store/api'
import { selectIsBrowser } from 'store/ui'
import HeaderCart from './HeaderCart'
import HeaderContact from './HeaderContact'
import HeaderFlyoutMenu from './HeaderFlyoutMenu'
import HeaderMobileMenu from './HeaderMobileMenu'
import HeaderSearch from './HeaderSearch/HeaderSearch'

let HeaderAuth = dynamic(
  () => import('components/layouts/HeaderAuth'),
  {
    ssr: false,
    loading: () => <LogInIcon className='h-6 w-6 text-grey' />,
  }
)

let SignInModal = dynamic(() => import('components/auth/SignInModal'))

export type Props = {
  menusSSR?: Record<string, IMenuItem[]>
}

let Header = ({ menusSSR }: Props) => {

  const cities = useSelector(selectCities('ru'))
  const defaultCity = useMemo(
    () => (isNonEmptyArray(cities) ? cities[0] : null),
    [cities]
  )

  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)

  let isBrowser = useSelector(selectIsBrowser)

  let { menu, drawerMenu, leftMenu } = useMenus(menusSSR)

  const [isStuck, setIsStuck] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if(isAbsent(ref?.current) || !isBrowser) return
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStuck(entry!.intersectionRatio < 1)
      },
      { threshold: [1] }
    )

    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ref, isBrowser])

  return (
    <>
      {defaultCity?.header?.headerMsg && (
        <div className='bg-logo hidden lg:block py-2 text-center text-sm text-white leading-6 tracking-tight'>
          {defaultCity?.header?.headerMsg}
        </div>
      )}
      <header className='z-40 sticky shadow-bottom-sticky top-0 lg:-top-1 bg-white lg:bg-transparent'>
        <div className='lg:bg-lightGrey lg:bg-spoons-pattern h-[85px] lg:h-full flex items-center justify-between custom-px'>
          <div className='flex items-center justify-start lg:justify-between lg:w-[40%] xl:w-1/3'>
            {isBrowser && (
              <a
                className={clsx(
                  'hidden lg:block font-semibold text-lg tracking-tighter whitespace-nowrap leading-snug'
                )}
                href={`tel:+${CONTACT_PHONE.replace(/[\s-]/g, '')}`}
              >
                {CONTACT_PHONE}
              </a>
            )}

            <div className='lg:hidden'>
              <HeaderMobileMenu
                drawerMenu={drawerMenu}
                leftMenu={leftMenu}
                setIsOpenCb={setIsSignInModalOpen}
              />
            </div>

            <HeaderAuth setIsOpenCb={setIsSignInModalOpen} />

            <IntLink
              href='/'
              className='lg:hidden ml-4 w-[72px] h-[25px] flex-shrink-0 relative'
            >
              <MobileLogo />
            </IntLink>
          </div>

          <IntLink
            href='/'
            className='hidden lg:block w-[124px] py-2 flex-shrink-0 relative'
          >
            {isStuck ? <MobileLogo className='py-2' /> : <HeaderLogo />}
          </IntLink>

          <div className='flex items-center gap-6 justify-end lg:justify-between lg:w-[40%] xl:w-1/3'>
            <HeaderContact />
            <HeaderSearch />
            <div className='relative'>
              <div className='lg:-ml-2 flex relative'>
                <HeaderCart />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div ref={ref}></div>
      <HeaderFlyoutMenu menu={menu} leftMenu={leftMenu} />

      {isSignInModalOpen && (
        <SignInModal setIsOpenCb={setIsSignInModalOpen} />
      )}
    </>
  )
}

export default Header
